<template>
  <v-list flat color="transparent" class="pt-4">
    <v-list-item-group>
      <v-list-item
        v-for="item in items"
        :key="item.title"
        link
        :to="`/${item.link}`"
        class="font-weight-medium ml-4 mr-6 my-1"
        active-class="active"
      >
        <v-list-item-icon class="my-4 mr-3">
          <v-img height="20" max-width="20" :src="getImage(item.icon)" class="icon"></v-img>
        </v-list-item-icon>
        <v-list-item-content class="py-2">
          <v-list-item-title class="white--text">{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: 'Dashboard', link: 'dashboard', icon: 'home' },
        { title: 'Staff management', link: 'staff', icon: 'staff' },
        { title: 'Requests', link: 'offer-requests', icon: 'request' },
        { title: 'Metrics', link: 'metrics?type=users', icon: 'metrics' },
      ],
    };
  },
  methods: {
    getImage(icon) {
      return require(`@/assets/icon/${icon}.svg`);
    },
  },
};
</script>

<style lang="scss" scoped>
.active {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 40px;
}
</style>
