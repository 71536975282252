<template>
  <div class="main-layout">
    <Header />
    <div class="d-flex">
      <notifications></notifications>
      <Menu class="d-none d-lg-block" />
      <router-view class="content-view" />
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header';
import Menu from '@/components/Menu';

export default {
  components: {
    Header,
    Menu,
  },
};
</script>

<style>
.main-layout .content-view {
  padding-top: 20px;
  padding-bottom: 20px;
  max-width: 1130px;
  width: 100%;
  margin: 0 auto;
}
@media screen and (max-width: 999px) {
  .main-layout .content-view {
    padding-left: 40px;
    padding-right: 40px;
  }
}
</style>
