<template>
  <div>
    <v-navigation-drawer color="#404072" fixed overlay-opacity temporary v-model="drawer" width="220" class="mobile-menu">
      <div class="logo">
        <v-img max-height="16" max-width="175" src="@/assets/img/logo.svg" alt="logo"></v-img>
      </div>
      <MenuList />
    </v-navigation-drawer>
    <v-app-bar elevation="0" height="68" color="white" class="header">
      <div class="d-flex flex-wrap align-center height100 width100 px-md-12">
        <v-app-bar-nav-icon @click="drawer = !drawer" class="d-lg-none d-block">
          <v-icon v-if="!drawer">mdi-menu</v-icon>
          <v-icon v-else>mdi-close</v-icon>
        </v-app-bar-nav-icon>
        <v-spacer></v-spacer>
        <!-- <v-btn fab depressed class="me-3 pa-0 primary darken-2" min-width="40" height="40" plain small>
          <v-badge color="error" class="link" content="+12" :value="0" bottom offset-x="0" offset-y="30">
            <v-img max-width="20" height="20" contain src="@/assets/icon/bell.svg"></v-img>
          </v-badge>
        </v-btn> -->
        <v-divider class="ms-7" vertical></v-divider>
        <v-menu offset-y nudge-bottom="10">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="d-flex align-center ml-5">
              <v-avatar size="50" color="gray lighten-2">
                <v-img v-if="data.picture" cover :src="data.picture" alt="avatar"></v-img>
                <v-img v-else cover src="@/assets/icon/empty-user.svg" alt="avatar"></v-img>
              </v-avatar>
              <div class="ms-2">
                <div class="font-weight-medium">{{ data.first_name }} {{ data.last_name }}</div>
                <div class="text-body-2 text--text text-capitalize">{{ data.role }}</div>
              </div>
            </div>
          </template>
          <v-list>
            <v-list-item link to="/profile">
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Profile</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="logout">
              <v-list-item-icon>
                <v-icon>mdi-download mdi-rotate-270</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Sing out</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
    <v-divider></v-divider>
  </div>
</template>

<script>
import MenuList from './MenuList.vue';

export default {
  components: {
    MenuList,
  },
  data() {
    return {
      drawer: false,
    };
  },
  mounted() {
    this.$store.dispatch('getProfile');
  },
  methods: {
    async logout() {
      await this.$store.dispatch('logout');
    },
  },
  computed: {
    data() {
      return this.$store.getters.profile;
    },
  },
};
</script>

<style>
.header .v-toolbar__content {
  width: calc(100% - 250px);
  margin-left: auto;
  padding-top: 0;
  padding-bottom: 0;
}
.mobile-menu {
  top: 64px !important;
  height: calc(100% - 64px) !important;
}
@media screen and (max-width: 1263px) {
  .header .v-toolbar__content {
    width: initial;
    margin-left: initial;
  }
}
</style>
