import '@/plugins/axios';
import router from '@/router/index';

const api = '/platform/api/v1/offer-feature-requests/';

export default {
  state: {
    requestList: {},
    request: {},
  },
  actions: {
    getRequestList(context, { search, page, status }) {
      if (!!search || page > 1 || status != 'all') {
        if (
          `${router.app._route.path}?${search ? `src=${search}&` : ''}${status != 'all' ? `status=${status}&` : ''}page=${page || 1}` !=
          router.app._route.fullPath
        ) {
          router.push(
            `${router.app._route.path}?${search ? `src=${search}&` : ''}${status != 'all' ? `status=${status}&` : ''}page=${page || 1}`
          );
        }
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          router.push(`${router.app._route.path}`);
        }
      }
      return axios
        .get(`${api}?${search ? `src=${search}&` : ''}${status != 'all' ? `status=${status}&` : ''}page=${page - 1 || 0}`)
        .then((res) => {
          context.commit('GET_REQUESTLIST', res.data);
        });
    },
    setRequestList: (context) => {
      context.commit('GET_REQUESTLIST', {});
    },
    getRequest(context) {
      return axios.get(`${api}${router.app._route.params.requestId}`).then((res) => {
        context.commit('GET_REQUEST', res.data);
      });
    },
    setRequest: (context) => {
      context.commit('GET_REQUEST', {});
    },
    actionRequest(context, payload) {
      return axios.post(`${api}${router.app._route.params.requestId}/action`, payload).then((res) => {
        context.commit('GET_REQUEST', res.data);
      });
    },
  },
  mutations: {
    GET_REQUESTLIST: (state, payload) => {
      state.requestList = payload;
      return state.requestList;
    },
    GET_REQUEST: (state, payload) => {
      state.request = payload;
      return state.request;
    },
  },
  getters: {
    requestList(state) {
      return state.requestList;
    },
    request(state) {
      return state.request;
    },
  },
};
