import '@/plugins/axios';

const api = '/platform/api/v1/metrics/';

export default {
  state: {
    metricDashboardCounter: {},
    metricPageCounter: {},
  },
  actions: {
    getMetricDashboardCounter(context) {
      return axios.get(`${api}dashboard/counters`).then((res) => {
        context.commit('GET_METRICSDASH', res.data);
      });
    },
    setMetricDashboardCounter: (context) => {
      context.commit('GET_METRICSDASH', {});
    },
    getMetricPageCounter(context, { page, type }) {
      return axios.get(`${api}${page}/counters${type ? `?post_type=${type}` : ''}`).then((res) => {
        context.commit('GET_METRICSPAGECOUNT', res.data);
      });
    },
    getMetricPageStatistics(context, { page, period, from, to, type }) {
      if (page == 'posts') {
        return axios.get(
          `${api}posts/statistics?post_type=regular&statistics_type=${type}&period=${period}${
            period == 'custom' ? `&period_from=${from}&period_to=${to}` : ''
          }`
        );
      } else if (page == 'wheels') {
        return axios.get(
          `${api}posts/statistics?post_type=wheels&statistics_type=${type}&period=${period}${
            period == 'custom' ? `&period_from=${from}&period_to=${to}` : ''
          }`
        );
      } else if (page == 'topics' || page == 'hashtags' || page == 'searches') {
        return axios.get(`${api}${page}/?type=${type}&period=${period}${period == 'custom' ? `&period_from=${from}&period_to=${to}` : ''}`);
      } else {
        return axios.get(
          `${api}${page}/statistics?type=${type}&period=${period}${period == 'custom' ? `&period_from=${from}&period_to=${to}` : ''}`
        );
      }
    },
  },
  mutations: {
    GET_METRICSDASH: (state, payload) => {
      state.metricDashboardCounter = payload;
      return state.metricDashboardCounter;
    },
    GET_METRICSPAGECOUNT: (state, payload) => {
      state.metricPageCounter = payload;
      return state.metricPageCounter;
    },
  },
  getters: {
    metricDashboardCounter(state) {
      return state.metricDashboardCounter;
    },
    metricPageCounter(state) {
      return state.metricPageCounter;
    },
  },
};
