import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: true,
      light: {
        primary: {
          darken2: '#20264d',
          darken1: '#1c2974',
          base: '#2c40b5',
          lighten1: '#3a50cf',
        },
        info: '#5428B5',
        cyan: '#16a696',
        pink: '#dc1d6d',
        success: {
          base: '#19bb43',
          lighten4: '#a2febd',
          lighten5: '#f4fef7',
        },
        error: {
          base: '#ff2306',
          lighten4: '#fbddd9',
          lighten5: '#fff5f3',
        },
        warning: {
          base: '#ff9c06',
          lighten4: '#fff6e3',
        },
        text: '#444c55',
        gray: {
          base: '#515151',
          lighten1: '#b8b8b8',
          lighten2: '#e0e0e0',
          lighten3: '#f7f7f7',
        },
      },
    },
  },
});
