<template>
  <div class="empty-layout">
    <div class="empty-layout_logo mt-10">
      <v-img max-height="36" max-width="365" contain src="@/assets/img/logo.svg" alt="logo"></v-img>
    </div>
    <notifications></notifications>
    <div class="empty-content py-4">
      <v-fab-transition>
        <router-view />
      </v-fab-transition>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.empty-layout {
  background: url(../assets/img/empty.png);
  background-position: 50% 90%;
  background-size: cover;
  &_logo {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
  }
  .empty-content {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    min-height: calc(100vh - 73.4px);
    display: flex;
    align-items: center;
  }
}
</style>
