<template>
  <v-card tile min-width="250" color="primary darken-2" class="card">
    <div class="logo">
      <v-img max-height="16" max-width="175" contain src="@/assets/img/logo.svg" alt="logo"></v-img>
    </div>
    <v-divider style="border-width: 1px" class="gray lighten-1"></v-divider>
    <MenuList />
  </v-card>
</template>

<script>
import MenuList from './MenuList.vue';

export default {
  components: {
    MenuList,
  },
};
</script>

<style scoped>
.card {
  margin-top: -69px;
  min-height: 100vh;
}
</style>
