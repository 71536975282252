import '@/plugins/axios';
import router from '@/router/index';

const api = '/platform/api/v1/';

export default {
  actions: {
    logout() {
      sessionStorage.removeItem('jwt_token');
      router.push('/sign-in');
    },
    signIn(context, payload) {
      return axios.post(`${api}sign-in/email`, payload);
    },
    otp(context, payload) {
      return axios.post(`${api}sign-in/otp`, payload);
    },
    otpRecovery(context, payload) {
      return axios.post(`${api}sign-in/otp-recovery`, payload);
    },
    passwordReset(context, payload) {
      return axios.post(`${api}request-password-reset`, payload);
    },
    reset(context, payload) {
      return axios.post(`${api}password-reset`, payload);
    },
    confirmEmail(context, payload) {
      return axios.post(`${api}confirm-email`, payload);
    },
    refresh(context, payload) {
      return axios.post(`${api}refresh`, payload);
    },
  },
};
