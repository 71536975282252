'use strict';

import Vue from 'vue';
import axios from 'axios';
// import store from './../store/index';
// import router from '@/router/index';

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
axios.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem('jwt_token')}`;
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('jwt_token');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response;
  },
  async function(error) {
    // Do something with response error
    // if (router.app._route.name != 'Email confirmation' || router.app._route.name != 'Password update') {
    //   if (error.response.status == 401) {
    //     await store.dispatch('refresh');
    //     error.response.config.headers.Authorization = `Bearer ${sessionStorage.getItem('jwt_token')}`;
    //     return axios(error.response.config);
    //   }
    // }
    return Promise.reject(error);
  }
);

Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
